import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAlert } from '../context/AlertContext';
import { getCookie, useRegistration } from '../context/RegistrationContext';
import { functions } from '../firebase';

/**
 * FitbitCallback component handles the callback from Fitbit's OAuth process.
 * It retrieves the authorization code and state from the URL, fetches the participant data,
 * and allows the user to agree to terms and proceed.
 */
function FitbitCallback() {
  const {
    pid,
    participantId,
    participant,
    setParticipant,
    loading,
    setLoading,
    error,
    getRegistrationUrl,
  } = useRegistration();

  // Get the participant ID and project ID from cookies
  var cookie_pid = getCookie("pid");
  var cookie_participantId = getCookie("participantId");

  // State variables
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [code, setCode] = useState(null);
  const [state, setState] = useState(null);
  const location = useLocation();

  //const navigate = useNavigate();
  const { addAlert } = useAlert();

  // Extract 'code' and 'state' from URL
  useEffect(() => {
    console.log("useEffect, addAlert, location.search");
    const searchParams = new URLSearchParams(location.search);
    const codeParam = searchParams.get('code');
    const stateParam = searchParams.get('state');

    setCode(codeParam);
    setState(stateParam);

    if (!codeParam || !stateParam) {
      console.log("Missing code or state in URL");
      addAlert('error', 'Missing code or state in URL');
    }
  }, [addAlert, location.search]);


  useEffect(() => {
    // I don't know why we are checking for the pid and participantId here
    console.log("useEffect, cookie_participantId, cookie_pid, participantId, pid",
        cookie_participantId, cookie_pid, participantId, pid);
  }, [cookie_participantId, cookie_pid, participantId, pid]);



  /**
   * Handles the completion of Fitbit authorization.
   *
   * This function performs the following steps:
   * 1. Checks if the loading state is true, if so, it returns early.
   * 2. Sets the loading state to true.
   * 3. Checks if the user has agreed to the terms and conditions, if not, it adds an alert and returns early.
   * 4. Calls the Firebase function to handle the authorization redirect.
   * 5. If the authorization is successful, it sets the participant data and adds a success alert.
   * 6. If the authorization fails, it logs the error, sets the error state, and adds an error alert.
   * 7. Finally, it sets the loading state to false and navigates to the next page.
   *
   * @async
   * @function handleCompleteFitbitAuth
   * @returns {Promise<void>} A promise that resolves when the Fitbit authorization process is complete.
   * @throws Will throw an error if the Fitbit authorization fails.
   */
  const handleCompleteFitbitAuth = async () => {
    if (loading) return;

    setLoading(true);
    if (!agreeTerms) {
      addAlert("error", "Please agree to the terms and conditions");
      setLoading(false);
      return;
    }

    try {
      // TODO: Fix this, it's were the error is coming from
      // I think it's because we are not passing the participantId to the function
      //
      // Call the Firebase function to handle the authorization redirect
      const handleAuthorizationRedirect = httpsCallable(functions, 'handleAuthorizationRedirect');
      const res = await handleAuthorizationRedirect({ code: code, state: state, pid: pid, participantId: participantId });
      console.debug("handleAuthorizationRedirect, res", res);
      if (res?.data?.participant?.fitbitData?.access_token) {
        setParticipant(res.data.participant);
        // TODO: Do I need anything else here?
        addAlert("success", "Fitbit authorization completed successfully");
      } else {
        console.error('Fitbit authorization failed');
        addAlert("error", "Fitbit authorization failed");
        return Promise.reject("Fitbit authorization failed");
      }
    } catch (err) {
      error(`Fitbit authorization failed: ${err?.message}`);
      // setError('Fitbit authorization failed.');
      addAlert('error', 'Fitbit authorization failed.');
    } finally {
      setLoading(false);
      // Navigate back to the registration page, with the participant ID if available
      const redirect_uri = getRegistrationUrl();
      if(!redirect_uri) {
        addAlert('error', 'Failed to get registration URL');
      }else{
        // TODO: deciced. let's just use window.location.href for now
        window.location.href = redirect_uri;
        //navigate(redirect_uri);
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
        <Button variant="contained" onClick={() => console.log("")}>
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom>
        ${participant?.pid} Fitbit Consent Form
      </Typography>
      <Card>
        <CardHeader title="Fitbit Consent Form" />
        <CardContent>
          {(loading) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box>
          )}
          <Typography variant="body1">
            Hereby I give my consent to share my Fitbit wearable data with Jamasp.
            I understand that my data will be used for research purposes and will be kept confidential.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            I understand that I can withdraw my consent at any time.
          </Typography>
          <FormControlLabel sx={{ mt: 3 }}
            control={<Checkbox value="accept" required
              onChange={() => setAgreeTerms(!agreeTerms)} color="primary" checked={agreeTerms} />}
            label="By clicking this box, I accept the Terms of Service and Privacy Policy."
          />
        </CardContent>
        <CardActions>
          <Button disabled={!agreeTerms || loading} variant="contained" color="primary" onClick={handleCompleteFitbitAuth}>
            Complete Authorization
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default FitbitCallback;