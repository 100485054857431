import React from 'react';
import { Box, Button, CircularProgress, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Define prop types for the component
const StepperPropTypes = {
  pid: PropTypes.string,
  activeStep: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onPidChange: PropTypes.func.isRequired,
  onStorePid: PropTypes.func.isRequired,
  onInitiateFitbitAuth: PropTypes.func.isRequired,
  onRenewRegistration: PropTypes.func.isRequired,
};

// Component with props using proper destructuring
function ProlificRegistrationStepper({
  pid,
  activeStep,
  loading,
  onPidChange,
  onStorePid,
  onInitiateFitbitAuth,
  onRenewRegistration,
}) {
  // Component logic using passed props
  const steps = ['Prolific ID', 'Fitbit Connection', 'Completion'];

  if (loading) {
    return (<CircularProgress />);
  }

  return (
    <Box>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <Box>
          <TextField
            label="Prolific ID"
            value={pid || ''}
            onChange={(e) => onPidChange(e.target.value)}
            fullWidth
            margin="normal"
            error={(pid && pid.length < 24)}
            helperText={pid && pid.length < 24 ? 'Prolific ID must be at least 24 characters long' : ''}
            inputProps={{ minLength: 24 }}
          />
          <Button variant="contained" onClick={onStorePid} disabled={!pid || pid.length < 24} >
            Next
          </Button>
        </Box>
      )}

      {(activeStep === 1 || activeStep === 2) && (
        <Box>
          <Typography variant="h5" gutterBottom>
            Fitbit Connection
          </Typography>
          <Button variant="contained" onClick={onInitiateFitbitAuth}>
            Connect to Fitbit
          </Button>
          <Button variant="contained" onClick={onRenewRegistration}>
            Renew Registration
          </Button>
        </Box>
      )}

      {activeStep > 3 && (
        <Box>
          <Typography variant="h5" gutterBottom>
            You are all set!
          </Typography>
          <Typography variant="body1">Your registration is complete.</Typography>
          <Button variant="contained" onClick={onRenewRegistration}>
            Start another registration
          </Button>
        </Box>
      )}
    </Box>
  );
}

ProlificRegistrationStepper.propTypes = StepperPropTypes;

export default React.memo(ProlificRegistrationStepper);
