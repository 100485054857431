// src/context/RegistrationContext.js
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { functions } from '../firebase';
import { findOrCreateNewParticipant,
  findParticipantById,
  findParticipantIdByPIdAndProjectId,
  updateParticipantRegistration
} from '../services/RegistrationService';
import { useAlert } from './AlertContext'; // Assume you have an AlertContext for notifications

// Create the context
const RegistrationContext = createContext();


// Custom hook to use context
export const useRegistration = () => useContext(RegistrationContext);
// Provider component
export const RegistrationProvider = ({ children }) => {
  // Retrieve URL parameters
  const { projectId, pId: pIdParams } = useParams();
  // State variables
  const [pid, setPid] = useState(pIdParams?.length > 0 ? pIdParams : null);
  const [participantId, setParticipantId] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(null);
  //const [success, setSuccess] = useState(null);

  // Use the AlertContext for notifications
  const { addAlert } = useAlert();

  // Initialize navigate function using useNavigate hook
  const navigate = useNavigate(); // useNavigate is a hook from react-router-dom

  // Load participant data from cookies on component mount
  // If cookie pId not found in DB, clear cookies (be sure this only runs once)
  // Effect hook to handle participant data loading from cookies and database synchronization
  useEffect(() => {
    // Async function to load and validate participant data
    const loadParticipantData = async () => {
      setLoading(true);
      try {
        // Retrieve participant ID and PID from cookies
        const cookiePid = getCookie('pid');
        const cookieParticipantId = getCookie('participantId');

        // Only proceed if both cookie values exist
        if (cookiePid && cookieParticipantId) {
          console.debug("loadParticipantData: cookiePid: %s, cookieParticipantId: %s: projectId: %s", cookiePid, cookieParticipantId,projectId);
          // Validate if the PID exists in the database for current project
          const dbParticipantId = await findParticipantIdByPIdAndProjectId({ pId: cookiePid, projectId });
          console.debug("loadParticipantData: dbParticipantId: %s", dbParticipantId);

          // If PID not found in database, reset registration process
          if (!dbParticipantId) {
            console.debug("loadParticipantData: dbParticipantId not found, clearing cookies");
            renewRegistration();
          } else {
            // Check for mismatch between state and cookies
            if (pid !== cookiePid || participantId !== cookieParticipantId) {
              console.debug("pid or participantId is different from cookiePid or cookieParticipantId, setting pid and participantId to cookiePid and cookieParticipantId, cookiePid: %s , pid: %s, cookieParticipantId: %s, participantId: %s", cookiePid, pid, cookieParticipantId, participantId);

              // Update PID if different
              if (pid !== cookiePid) {
                setPid(cookiePid);
              }

              // Update participant data if participant ID is different
              if (participantId !== cookieParticipantId) {
                setParticipantId(cookieParticipantId);
                // Fetch and set complete participant data
                const participantData = await findParticipantById(cookieParticipantId);
                if (participantData) {
                  setParticipant(participantData);
                  setActiveStep(participantData.registrationStep);
                }
              }
            }
          }
        }
      } catch (err) {
        console.error('Error loading participant data:', err);
        addAlert('error', 'Error loading participant data, Please try again');
      } finally {
        setLoading(false);
      }
    };

    console.debug("loadParticipantData: calling loadParticipantData");
    // Execute the load function
    loadParticipantData();

    // set the projectId in the cookie for 7 days
    setCookie('projectId', projectId,7);
    // eslint-disable-next-line
  }, []);  // run only once on mount
  // Used to Re-run effect when participantId or pid changes

  useEffect(() => {
    if (!!participant?.registrationStep && participant?.registrationStep !== activeStep) {
      console.debug("setting activeStep to %s", participant.registrationStep);
      setActiveStep(participant.registrationStep);
    }
  }, [activeStep, participant]);


  // Function to handle Prolific ID submission and load the participant with pid or create a new one
  const storePid = async () => {
    setLoading(true);
    // Check if Prolific ID is valid
    if (!pid || pid.length < 23) {
      //setError('Please enter your Prolific ID');
      addAlert('error', 'Invalid Prolific ID, Please enter your Prolific ID');
      setLoading(false);
      return;
    }
    try {
      // Create or find participant and get their ID
      const newParticipantId = await findOrCreateNewParticipant(pid, projectId);
      // Fetch complete participant data
      const participantData = await findParticipantById(newParticipantId);
      if (participantData?.fitbitData?.accessToken?.length > 0) {
        console.debug("storePid: participantData.fitbitData.accessToken:", participantData?.fitbitData?.accessToken);
      }
      // Set participant ID in state
      setParticipantId(newParticipantId);
      // Set cookie here when user explicitly submits
      setCookie('pid', pid, 7);
      setCookie('participantId', newParticipantId);
      // if participantData exists, set it in state
      if (participantData) {
        setParticipant(participantData);
        setActiveStep(participantData.registrationStep);
      }
      // check how setSuccess works
      addAlert('success', 'Successfully stored Prolific ID');
    } catch (err) {
      console.error('Error in creating new participant:', err);
      addAlert('error', 'Error registering your Prolific ID. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to initiate Fitbit authorization
  const initiateFitbitAuth = async () => {
    setLoading(true);
    try {
      const generateFitbitAuthData = httpsCallable(functions, 'generateVerifierAndChallenge');
      const result = await generateFitbitAuthData({ pid, participantId });

      if (result?.data?.redirectUri) {
        window.location.href = result.data.redirectUri;
      } else {
        console.error('Error in generating verifier and challenge');
        addAlert('error', 'Error initiating Fitbit authorization. Please try again.');
      }
    } catch (err) {
      console.error('Error initiating Fitbit authorization:', err);
      addAlert(
        'error',
        'Error initiating Fitbit authorization. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  // Update the registration step for the participant using the backend
  const updateRegistrationStep = async (step, additionalData = {}) => {
    setLoading(true);
    try {
      const updatedParticipant = await updateParticipantRegistration(participantId, step, additionalData);
      if (updatedParticipant) {
        setParticipant(updatedParticipant);
        setActiveStep(step);
      }
    } catch (err) {
      addAlert('error', 'Error updating registration step');
    } finally {
      setLoading(false);
    }
  };

  // Renew the registration process
  const renewRegistration = async () => {
    setLoading(true);
    try {
      console.debug("renewRegistration: pid: %s, participantId: %s", pid, participantId);
      // Clear cookies and reset state
      clearCookies(['pid', 'participantId']);
      setActiveStep(0);
      setPid(null);
      setParticipantId(null);
      setParticipant(null);
      console.debug("renewReg cleared cookies and reset state");
      addAlert('success', 'Registration process has been reset.');
      // Redirect to the registration page
      navigate(`/reg/prolificRegistration/${projectId}`);
    } catch (err) {
      console.error('Error resetting registration process:', err);
      addAlert(
        'error',
        'Error resetting registration process. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  const getRegistrationUrl = () => {
    const cookie_projectId = getCookie('projectId');
    const cookie_pid = getCookie('pid');
    const cookie_participantId = getCookie('participantId');
    console.log("getRegistrationUrl: cookie_projectId: %s, cookie_pid: %s, cookie_participantId: %s", cookie_projectId, cookie_pid, cookie_participantId);
    console.log("getRegistrationUrl: projectId: %s, pid: %s, participantId: %s", projectId, pid, participantId);
    if ((!projectId && !cookie_projectId) || (!pid && !cookie_pid)) {
      console.error("getRegistrationUrl: projectId or pid is missing, cannot generate URL");
      return null;
    }
    if (projectId?.length >0 && projectId !== cookie_projectId) {
      console.warn("getRegistrationUrl: projectId is different from cookie_projectId, we should be setting projectId to cookie_projectId");
      //setCookie('projectId', projectId);
    }
    const projId = cookie_projectId || projectId;
    const pId = cookie_pid || pid;
    const uri = `/reg/prolificRegistration/${projId}${pId ? '/pId/' + pId : ''}`;
    console.debug("getRegistrationUrl: ouput: %s", uri);
    return uri;
  };

  return (
    <RegistrationContext.Provider
      value={{
        pid,
        setPid,
        projectId,
        participantId,
        participant,
        setParticipant,
        activeStep,
        loading,
        setLoading,
        storePid,
        initiateFitbitAuth,
        updateRegistrationStep,
        renewRegistration,
        getRegistrationUrl,
        //isProjectOpen
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

/**
 * Retrieves the value of a specified cookie by name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string} The value of the cookie if found, otherwise an empty string.
 */
// Function to retrieve a cookie value by name
function getCookie(name) {
  // Get all cookies as an array
  const cookies = document.cookie ? document.cookie.split(';') : [];
  // Variable to hold the cookie value
  let cookieValue = null;
  // Loop through the cookies
  for (let i = 0; i < cookies.length; i++) {
    // Get the current cookie and trim whitespace
    const cookie = cookies[i].trim();
    // Check if the cookie name matches
    if (cookie.substring(0, name.length + 1) === (name + '=')) {
      // Decode and assign the cookie value
      cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
      break;
    }
  }
  // Return the cookie value or null
  return cookieValue;
}
/**
 * Sets a cookie with the specified name, value, and expiration time.
 * Includes security attributes and proper value encoding.
 *
 * @param {string} name - The name of the cookie
 * @param {string} value - The value of the cookie
 * @param {number} [expiresInDays=3] - Days until cookie expires
 * @returns {boolean} - Success status of cookie setting
 */
const setCookie = (name, value, expiresInDays = 30) => {
  try {
    // Validate inputs
    if (!name || !value) {
      console.error('Cookie name and value are required');
      return false;
    }

    // Calculate expiration
    const expires = new Date(Date.now() + expiresInDays * 86400e3).toUTCString();

    // Encode value to handle special characters
    const encodedValue = encodeURIComponent(value.trim());

    // Set cookie with security attributes
    document.cookie = `${name}=${encodedValue}; `
      + `path=/; `
      + `expires=${expires}; `
      + `SameSite=Strict; `
      + (window.location.protocol === 'https:' ? 'Secure;' : '');

    // Verify cookie was set
    return document.cookie.includes(`${name}=`);

  } catch (err) {
    console.error('Error setting cookie:', err);
    return false;
  }
};

// Clear cookie by setting expiration to past date and empty value
const clearCookies = (names) => {
  try {
    // For all names in names Set cookie with expiration in the past to clear it
    names.forEach((name) => {
      document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict; Secure;`;
    });
    return true;
  } catch (err) {
    console.error('Error clearing cookie:', err);
    return false;
  }
}

// Define prop types for RegistrationProvider
RegistrationProvider.propTypes = {
  children: PropTypes.node.isRequired, // Prop type validation for children
};

export { clearCookies, getCookie, setCookie };
